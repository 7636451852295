<template>
  <div class="main-wrapper">
    <template>
     <loader v-if="isLoaded"
     object="#3370b7" 
     color1="#ffffff" 
     color2="#17fd3d" 
     size="5"
     speed="2" 
     bg="#ffffff" 
     objectbg="#cfcfcf" 
     opacity="80"
      disableScrolling="false"
       name="spinning"></loader>
    </template>
    <title-bar
      title="Contact Us"
      subtitle=""
    />
    <div class="content">
      <div class="container">
        <div class="contact-wrapper">
          <div class=" pt-0">
            <div class="row">
              <div class="col-12 mb-4">
                <h2>Contact Us</h2>
                <p class="lead">
                  Get in touch and let us know how we can help. Fill out the form and we’ll be in touch as
                  soon as possible.
                </p>
              </div>
            </div>
            <div class="contact-form-wrapper">
              <div class="row">
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <div class="input-group input-group-alternative" v-bind:class="{ error: errors.firstName }">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ni ni-user-run" /></span>
                      </div>
                      <input
                        class="form-control"
                        placeholder="First Name"
                        type="text"
                        v-model="obj.firstName"
                        v-bind:class="{ 'is-invalid': errors.firstName != '' }"
                      >
                    </div>
                     <small class="text-danger">{{errors.firstName}}</small>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <div class="input-group input-group-alternative" v-bind:class="{ error: errors.lastName }">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ni ni-user-run" /></span>
                      </div>
                      <input
                        class="form-control"
                        placeholder="Last Name"
                        type="text"
                        v-model="obj.lastName"
                        v-bind:class="{ 'is-invalid': errors.lastName != '' }"
                      >
                    </div>
                     <small class="text-danger">{{errors.lastName}}</small>
                  </div>
                </div>
                
                <div class="col-lg-6">
                  <div class="form-group mb-4 ">
                    <div class="input-group input-group-alternative" v-bind:class="{ error: errors.email }">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ni ni-email-83" /></span>
                      </div>
                      
                      <input
                        class="form-control "
                        placeholder="Email address"
                        type="email"
                        v-model="obj.email"
                        v-bind:class="{ 'is-invalid': errors.email }"
                      >
                     
                    </div>
                     <small class="text-danger">{{errors.email}}</small>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="form-group mb-4">
                    <div class="input-group input-group-alternative" v-bind:class="{ error: errors.subject }">
                      <div class="input-group-prepend">
                        <span class="input-group-text"><i class="ni ni-user-run" /></span>
                      </div>
                      <input
                        class="form-control"
                        placeholder="Subject"
                        type="text"
                        v-model="obj.subject"
                        v-bind:class="{ 'is-invalid': errors.subject }"
                      >
                    </div>
                     <small class="text-danger">{{errors.subject}}</small>
                  </div>
                </div>
              </div>
              <div class="form-group mb-4">
                <textarea
                  class="form-control form-control-alternative"
                  name="name"
                  rows="4"
                  cols="80"
                  placeholder="Type a message..."
                  v-model="obj.message"
                  v-bind:class="{ error: errors.message }"
                />
               <small class="text-danger">{{errors.message}}</small>

              </div>
              <div>
                <button
                  type="button"
                  @click="sendEmail"
                  class="btn btn-primary btn-round btn-block btn-lg"
                >
                  Send Message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {axios} from '../services/http-common';
import TitleBar from 'Components/globalFrontendComponents/TitleBar';
export default {
  
   created () {
      document.title = "Contact Us - Thriggle";
  },
  data(){
    return{
      isLoaded :false,
      obj:{
      firstName:'',
      lastName:'',
      email:'',
      subject:'',
      message:''
      },
      errors:{
      firstName:'',
      lastName:'',
      email:'',
      subject:'',
      message:''
      },
    
    }
  },
	components: {
		TitleBar: TitleBar
	},
  methods:{
    validation(){
      if(this.obj.email == '' || this.obj.firstName  == '' || this.obj.lastName  == '' || this.obj.subject  == '' || this.message  == '')   
      {
      if(this.obj.email == '')
        this.errors.email = 'Email Required'
      else
        this.errors.email = ''

      if(this.obj.firstName == '')
        this.errors.firstName = 'First Name Required'
      else
       this.errors.firstName = ''

      if(this.obj.lastName == '')
        this.errors.lastName = 'Last Name Required'
      else
       this.errors.lastName = ''

      if(this.obj.message == '')
        this.errors.message = 'Message Required'
      else
       this.errors.message = ''
     
      if(this.obj.subject == '')
        this.errors.subject = 'Subject Required'
      else 
        this.errors.subject = ''
        return false;
    }else
      return true;
    },
    sendEmail () {
      let result  = this.validation();
      if(result){
        this.isLoaded = true;
        axios
        .post(`${process.env.VUE_APP_API_URL}contact-us`,this.obj)
        .then(response => {

            if (!response.error) {
              this.isLoaded = false;
              // alert('Success');
              this.$toast.success("Your Message has been Received. We will contact you soon", {
                  position: "bottom-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false
                });
            }
        }).catch(error => {
          this.isLoaded = false;
          this.$toast.error("Something Went Wrong!", {
                  position: "bottom-right",
                  timeout: 5000,
                  closeOnClick: true,
                  pauseOnFocusLoss: true,
                  pauseOnHover: true,
                  draggable: true,
                  draggablePercent: 0.6,
                  showCloseButtonOnHover: false,
                  hideProgressBar: true,
                  closeButton: "button",
                  icon: true,
                  rtl: false
                });
        });
      }
    }
  }
};
</script>
<style scoped>
.error{
  border:1px solid rgb(214, 106, 106);
}
</style>
